import DateTime from 'luxon/src/datetime';
import clone from 'clone';
import { usePrevious } from './HookUtils';
import { useEffect } from 'react';
import { STATUS_GROUPS, TYPE_STATUS } from '../pages/Overview/StatusGroups';

export const MAX_MACHINE_TIMEOUT = 2 * 60 * 1000;

/** Turns a recipe status (e.g. '1') to a descrition ('Recipe') */
export function recipeStatusToDescription(
    { recipeStatus, isCleaningRecipe, timestamp },
    noDisconnected
) {
    const now = DateTime.local();
    if (!noDisconnected && timestamp && now - timestamp > MAX_MACHINE_TIMEOUT)
        return 'Disconnected';
    if (recipeStatus === '0') return 'Idle';
    if (recipeStatus === 'K') return 'Lock';
    if (recipeStatus === 'T') return 'Fault';
    if (['S', 'L', 'F'].indexOf(recipeStatus) > -1) return 'Standby';
    if (recipeStatus === 'P') return 'Portion Inside';

    if (['1', '2', '3', '4', '5', '6', '7'].indexOf(recipeStatus) > -1) {
        if (isCleaningRecipe) {
            return 'Clean';
        } else {
            return 'Recipe';
        }
    }
}

/** Adds a parent node to each site/cluster - changes each item in-place */
export function addParent(item, parent) {
    item.parent = parent;
    if (!item.children) return;

    item.children.map((x) => addParent(x, item));
}

/** Calculates machine counts for each client/site/cluster - changes each item in-place */
export function calculateMachineCount(item) {
    if (!item.children) return 1; // It's a leaf node (=machine)

    const machineCount = item.children.reduce(
        (total, child) => total + calculateMachineCount(child),
        0
    );
    item.machineCount = machineCount;

    return machineCount;
}

/** Returns all sites that are parents to the input machines */
export function getAllSites(machines) {
    const sitesById = {};

    machines.forEach((m) => {
        const site = m.parent.parent;
        sitesById[site._id] = site;
    });

    return Object.values(sitesById);
}

/** Returns all clusters (by ID) from input client tree */
export function getAllClustersByClients(clients) {
    const clustersById = {};

    clients.forEach((client) => {
        client.children.forEach((site) => {
            site.children.forEach((cluster) => {
                clustersById[cluster._id] = cluster;
            });
        });
    });

    return clustersById;
}

/** Gets all machines under a client/site/cluster */
export function getAllChildMachines(item) {
    let machines = [];

    if (!item.children) {
        // Reached a machine
        return [item];
    }

    item.children.forEach((child) => {
        machines = machines.concat(getAllChildMachines(child));
    });

    return machines;
}

/** Clones an item (client/cluster/site/machine), without the parent/children properties (to avoid circular references) */
export function cloneItemOnly(item) {
    const parent = item.parent;
    const children = item.children;

    delete item.parent;
    delete item.children;

    const cloned = clone(item);

    item.parent = parent;
    item.children = children;

    return cloned;
}

/** Returns true if a machine list has changed from one version to another */
export function machineListChanged(newList, prevList) {
    if (!newList || !prevList) return false;
    if (newList.length !== prevList.length) return true;

    const list1 = newList.map((m) => m._id);
    const list2 = prevList.map((m) => m._id);

    return JSON.stringify(list1) !== JSON.stringify(list2);
}

/** Hook function that calls onChange callback when machine list has changed */
export function useMachineListChanged(machineList, onChange) {
    const prevList = usePrevious(machineList);

    useEffect(() => {
        if (!machineListChanged(machineList, prevList)) return;

        onChange(machineList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [machineList]);
}

export const SORT_TYPE_ALPHABETICAL = 'sort_a_z';
export const SORT_TYPE_ALPHABETICAL_REVERSE = 'sort_z_a';
export const SORT_TYPE_CAPSULE_HIGH_TO_LOW = 'sort_capsule_high_low';
export const SORT_TYPE_CAPSULE_LOW_TO_HIGH = 'sort_capsule_low_high';
export const SORT_TYPE_MACHINES_HIGH_TO_LOW = 'sort_machines_high_low';
export const SORT_TYPE_MACHINES_LOW_TO_HIGH = 'sort_machines_low_high';

/** Sorts the items according to sort type */
export function sortItems(items, sortType) {
    let sortFn;
    let nameField = 'name';

    if (items.length > 0 && items[0].previous_clusters) {
        // It's a machine item - sort by alias
        nameField = 'alias';
    }
    if (items.length > 0 && items[0].title) {
        nameField = 'title';
    }
    if (items.length > 0 && items[0].name) {
        nameField = 'name';
    }

    if (sortType === SORT_TYPE_ALPHABETICAL) {
        sortFn = (a, b) => a[nameField].localeCompare(b[nameField]);
    } else if (sortType === SORT_TYPE_ALPHABETICAL_REVERSE) {
        sortFn = (a, b) => b[nameField].localeCompare(a[nameField]);
    } else if (sortType === SORT_TYPE_CAPSULE_HIGH_TO_LOW) {
        sortFn = (a, b) => b.capsule_count - a.capsule_count;
    } else if (sortType === SORT_TYPE_CAPSULE_LOW_TO_HIGH) {
        sortFn = (a, b) => a.capsule_count - b.capsule_count;
    } else if (sortType === SORT_TYPE_MACHINES_HIGH_TO_LOW) {
        sortFn = (a, b) => b.machineCount - a.machineCount;
    } else if (sortType === SORT_TYPE_MACHINES_LOW_TO_HIGH) {
        sortFn = (a, b) => a.machineCount - b.machineCount;
    }

    items.sort(sortFn);

    // Now sort the children of each item
    items.map((item) => {
        return sortItems(item.children || [], sortType);
    });
}

function findItemInner(currentItem, itemId) {
    if (currentItem._id === itemId) return currentItem;

    if (!currentItem.children) return null;

    for (const index in currentItem.children) {
        const result = findItemInner(currentItem.children[index], itemId);
        if (result !== null) {
            return result;
        }
    }

    return null;
}

/** Finds and returns an item in the input tree */
export function findItem(items, itemId) {
    for (const index in items) {
        const result = findItemInner(items[index], itemId);
        if (result !== null) {
            return result;
        }
    }
}

/** Recursively marks an items and all of its children as checked/unchecked */
export function markItem(item, checked, cb) {
    if (cb) {
        item.selected = cb(item, checked);
    } else {
        item.selected = checked;
    }

    if (item.children) {
        item.children.map((child) => {
            return markItem(child, checked, cb);
        });
    }
}

export function formatClientTitle(item) {
    if (item.site) {
        // Cluster
        return `${item.parent.parent.name} / ${item.parent.name} / ${item.name}`;
    } else if (item.client) {
        // Site
        return `${item.parent.name} / ${item.name}`;
    } else if (item.region) {
        // Client
        return item.name;
    }
}

/** Finds selected machine (machine that belong to selected clients/sites/clusters) */
function findSelectedMachinesFromItem(item) {
    let machines = [];

    if (item.children) {
        item.children.forEach((c) => {
            machines = machines.concat(findSelectedMachinesFromItem(c));
        });

        return machines;
    } else {
        // Reached a machine
        return item.selected ? [item] : [];
    }
}

function findSelectedClustersFromItem(item) {
    let clusters = [];

    if (item.children) {
        let returnThisCluster = false;
        item.children.forEach((c) => {
            if (item.site) {
                // Current item is a cluster
                if (findSelectedClustersFromItem(c)) returnThisCluster = true;
            } else {
                clusters = clusters.concat(findSelectedClustersFromItem(c));
            }
        });
        if (item.site && item.selected) {
            returnThisCluster = true;
        }

        if (returnThisCluster) {
            return [item];
        }

        return clusters;
    } else {
        // Reached a machine
        return item.selected;
    }
}

export function findSelectedSites(clients) {
    let sites = [];

    clients.forEach((c) => {
        c.children.forEach((s) => {
            if (s.selected) {
                sites = sites.concat(s);
            }
        });
    });

    return sites;
}

export function findSelectedClusters(clients) {
    let clusters = [];

    clients.forEach((c) => {
        clusters = clusters.concat(findSelectedClustersFromItem(c));
    });

    return clusters;
}

export function findSelectedMachines(clients) {
    let machines = [];

    clients.forEach((c) => {
        machines = machines.concat(findSelectedMachinesFromItem(c));
    });

    return machines;
}

function findAllMachinesFromItem(item) {
    let machines = [];

    if (item.children) {
        item.children.forEach((c) => {
            machines = machines.concat(findAllMachinesFromItem(c));
        });

        return machines;
    }

    if (item.previous_clusters) {
        // Reached a machine
        return [item];
    }
}

export function findAllMachines(clients) {
    let machines = [];

    clients.forEach((c) => {
        machines = machines.concat(findAllMachinesFromItem(c));
    });

    return machines;
}

const areSetsEqual = (a, b) =>
    a.size === b.size && [...a].every((value) => b.has(value));

/** Goes over the client tree once, marking/tagging each node on the
 * way (client/site/cluster/machine) to which status groups it belongs to.
 * If ignoreSelected is true (happens when no filter is applied), it will not
 * take into account if a node.selected property is true. */
export function markItemForStatusGroups(item, ignoreSelected) {
    if (!ignoreSelected && !item.selected) {
        // Don't mark this item (or its children) since it's not selected
        return [false, []];
    }

    let changed = false;

    if (item.children) {
        // This item is not a machine (it's a client/site/cluster) - the status
        // groups it belongs to are determined by its machine descendants.
        item.statusGroups = new Set();

        item.children.forEach((c) => {
            const res = markItemForStatusGroups(c, ignoreSelected);
            if (res[0]) changed = true;
            item.statusGroups = new Set([...item.statusGroups, ...res[1]]);
        });
    } else {
        // Reached a machine - See to which status groups it belongs to

        let matchedGroups = STATUS_GROUPS.filter((s) => s.checkMachine(item));
        const worstStatus = matchedGroups.sort(
            (a, b) => b.priority - a.priority
        );

        // Only put the machine in the "worst" matched category
        if (worstStatus.length > 0) {
            matchedGroups = [worstStatus[0]];
        }

        const newStatusGroups = new Set(matchedGroups.map((s) => s.title));
        if (item.statusGroups) {
            changed = !areSetsEqual(newStatusGroups, item.statusGroups);
        }
        item.statusGroups = newStatusGroups;
    }

    return [changed, item.statusGroups];
}

/** Unselects a machine and its parents, if needed */
export function unselectItem(item) {
    if (item.previous_clusters) {
        // It's a machine - immediately unselect it
        item.selected = false;

        // Unselect parents if needed
        unselectItem(item.parent);
        return;
    }

    if (!item.children.every((c) => c.selected)) {
        // Not all of these parent's children are selected - unselect this item
        item.selected = false;
        if (item.parent) {
            unselectItem(item.parent);
        }
    }
}

/** Calculates capsule counts for each client/site/cluster - changes each item in-place */
export function calculateCapsuleCount(item) {
    if (!item.children) return item.capsule_count; // It's a leaf node (=machine)

    const capsuleCount = item.children.reduce(
        (total, child) => total + calculateCapsuleCount(child),
        0
    );
    item.capsule_count = capsuleCount;

    return capsuleCount;
}

export function getSelected(items) {
    return items.filter((x) => x.selected);
}

export function getSelectedSites(clients) {
    let sites = [];
    clients.forEach((client) => {
        sites = sites.concat(getSelected(client.children));
    });

    return sites;
}

export function getSelectedClusters(clients) {
    let clusters = [];
    clients.forEach((client) => {
        client.children.forEach((site) => {
            clusters = clusters.concat(getSelected(site.children));
        });
    });

    return clusters;
}

export const ID_ALL_CLIENTS = 'all_clients';

export function generateAllClientsItem(items) {
    return {
        name: 'All Clients',
        _id: ID_ALL_CLIENTS,
        children: [],
        region: true,
        visible: true,
        selected: true,
        color: '#6ae147',
        capsule_count: items
            .map((x) => x.capsule_count)
            .reduce((a, b) => a + b, 0),
    };
}

export const ID_ALL_FLAVOURS = 'all_flavours';

export function generateAllFlavoursItem(items) {
    return {
        title: 'All Flavours',
        _id: ID_ALL_FLAVOURS,
        visible: true,
        selected: true,
        categories: [],
        capsule_count: items
            .map((x) => x.capsule_count)
            .reduce((a, b) => a + b, 0),
    };
}

export function markSelectedItems(items, selectedItems) {
    if (items.length === 0) return;

    if (
        selectedItems.length === 1 &&
        (selectedItems[0]._id === ID_ALL_CLIENTS ||
            selectedItems[0]._id === ID_ALL_FLAVOURS)
    ) {
        // "All items" selection
        items[0].selected = true;
        return;
    }

    items.map((item) => {
        if (selectedItems.find((x) => x._id === item._id)) {
            // Current item is selected
            item.selected = true;
        } else {
            item.selected = false;
        }

        if (item.children) {
            markSelectedItems(item.children, selectedItems);
        }

        return null;
    });
}

export function getMachineHierarchy(item) {
    let hierarchy = item.name || item.alias;
    while (item.parent) {
        item = item.parent;
        const name = item.name || item.alias;
        hierarchy += ` / ${name}`;
    }

    return hierarchy;
}

function copyMachineListExpansionInner(filterClients, newClient) {
    const matchingMachine = findItem(filterClients, newClient._id);

    if (matchingMachine) {
        newClient.expanded = matchingMachine.expanded;
    }

    if (!newClient.children) return;

    newClient.children.forEach((c) => {
        copyMachineListExpansionInner(filterClients, c);
    });
}

export function copyMachineListExpansion(filterClients, newClients) {
    newClients.forEach((c) => copyMachineListExpansionInner(filterClients, c));
}
