import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Authentication from '../../../modules/Authentication';
import forwardIcon from '../../../res/images/right_circle.svg';
import Button from '../../../components/Button';
import Checkbox from '../../../components/Checkbox';
import MachineList from '../../../components/MachineList';
import {
    findItem,
    findSelectedClusters,
    findSelectedMachines,
    getMachineHierarchy,
    markItem,
    SORT_TYPE_ALPHABETICAL,
    sortItems,
} from '../../../modules/MachineUtils';
import clsx from 'clsx';
import clone from 'clone';
import { hexColorToCSSFilter } from '../../../modules/CSSUtils';
import SearchField from '../../../components/SearchField';
import { useDispatch, useSelector } from 'react-redux';
import {
    setAdminCountries,
    setAdminFilterMachines,
    setAdminFWVersions,
} from '../../../store/actions/admin';
import Select from '../../../components/Select';
import Dialog from '@material-ui/core/Dialog';

// Refresh machine and cluster list every 60 seconds
const PAGE_REFRESH_RATE = 60000;

const NON_SUPPORTED_FW_VERSIONS = ['R91', 'R09', 'R08', 'R07'];

const useStyles = makeStyles({
    dialog: {
        width: '90%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '15px',
    },
    dialogMessage: {
        fontSize: '20px',
        marginBottom: '20px',
        textAlign: 'center',
    },
    list: {
        width: '100%',
        flexGrow: 1,
        backgroundColor: '#F9FAFB',
        '--scrollbarBG': '#E1E5ED',
        '--thumbBG': '#7E8CAB',
        height: 'auto !important',
        borderTop: '1px solid #B5BFCC',
    },
    listItemClass: {
        height: '50px',
    },
    nonSupportedFWVersion: {
        color: 'red',
    },
    currentFWVersion: {
        color: 'green',
    },
    search: {
        width: '100%',
        height: '50px',
        marginBottom: '10px',
        background: '#E1E5ED',
        alignItems: 'center',
        borderBottom: '1px #B5BDCD solid',
    },
    searchFieldInput: {
        color: '#000000',
    },
    searchFieldIcon: {
        filter: hexColorToCSSFilter('#7E8CAB'),
    },
    machineFWVersion: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginRight: '10px',
        borderBottom: '1px solid #B5BFCC',
        height: '100%',
    },
    machineItemContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: '#F1F3F8',
        borderRight: '1px solid #B5BFCC',
    },
    machineItemLabel: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        color: '#828FAD',
        textTransform: 'uppercase',
        height: '100%',
        width: '100%',
        borderBottom: '1px solid #B5BFCC',
        paddingLeft: '8px',
    },
    machineItemSubLabel: {
        fontSize: '10px',
    },
    itemCheckIcon: {
        backgroundColor: '#E1E6ED66',
        border: '1px solid #B5BFCC',
        borderRadius: '4px',
        'input:hover ~ &': {
            backgroundColor: 'rgba(225,230,237,0.71)',
        },
    },
    itemCheckedOnIcon: {
        backgroundColor: '#E1E6ED66',
        '&:before': {
            filter: hexColorToCSSFilter('#344269'),
        },
        'input:hover ~ &': {
            backgroundColor: 'rgba(225,230,237,0.71)',
        },
    },
    machineItemCheckboxContainer: {
        width: '50px',
        minWidth: '50px',
        height: '100%',
        borderBottom: '1px solid #B5BFCC',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    successMessage: {
        textAlign: 'center',
        marginTop: '10px',
        fontSize: '18px',
        fontWeight: 'bold',
        color: 'green',
    },
    field: {
        marginBottom: '7px',
        width: '100%',
    },
    formRow: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '24px',
        width: '100%',
        alignItems: 'center',
    },
    formRowColumn: {
        flexDirection: 'column',
    },
    formRowTitle: {
        marginBottom: '10px',
        textTransform: 'uppercase',
        color: '#586374',
    },
    formSubRow: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '24px',
        width: '100%',
        alignItems: 'center',
        paddingLeft: '20px',
    },
    error: {
        color: 'red',
        fontWeight: 'bold',
    },
    formLabel: {
        textTransform: 'uppercase',
        fontSize: '16px',
        color: '#586374',
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
    },
    formFieldFull: {
        width: '100%',
    },
    confirmSubmit: {
        width: '220px',
        marginTop: '10px',
    },
    buttonRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    sortButton: {
        marginRight: '10px',
        marginBottom: '10px',
        paddingLeft: '10px',
        minWidth: '93px',
        height: '24px',
        maxHeight: '24px',
        minHeight: '24px',
        border: '1px solid #7D90AA',
        borderRadius: '4px',
        backgroundColor: '#E1E5ED',
        color: '#7D90AA',
        boxShadow: 'none',
        textAlign: 'center',
        fontSize: '10px',
        '&:last-of-type': {
            marginRight: '0px',
        },
        '&:hover': {
            backgroundColor: '#FFFFFF',
        },
        '&.Mui-selected': {
            backgroundColor: '#7D90AA',
            color: '#E1E5ED',
        },
    },
    sortButtonIcon: {
        filter: hexColorToCSSFilter('#ffffff'),
    },
    buttonLabel: {
        color: '#7D90AA',
        textAlign: 'center',
        fontSize: '10px',
        paddingLeft: 0,
        paddingRight: 0,
    },
    clearLabel: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    clearLabelText: {
        marginRight: '12px',
    },
    clearIcon: {
        filter: hexColorToCSSFilter('#939FB9'),
        height: '100%',
        width: '24px',
        borderLeft: '#939FB9 1px solid',
    },
});

const gApiClient = Authentication.getAPIClient();

export default function UpdateFWForm(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const filter = useSelector((state) => state.admin);
    const [countries, setCountries] = useState(filter.countries);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [machines, setMachines] = useState(filter.filterMachines);
    const [fwVersions, setFWVersions] = useState(filter.fwVersions);
    const [selectedFWVersion, setSelectedFWVersion] = useState(null);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [selectedMachines, setSelectedMachines] = useState([]);
    const [selectedClusters, setSelectedClusters] = useState([]);
    const formsWindow = useRef(null);
    const [selectedRegions, setSelectedRegions] = useState(new Set([]));
    const [clientsSearchText, setClientsSearchText] = useState('');
    const disallowFormSubmission =
        (selectedMachines.length === 0 && selectedClusters.length === 0) ||
        !selectedFWVersion;

    useEffect(() => {
        async function loadAllData(promises) {
            props.onLoading(true);
            await Promise.all(promises);
            props.onLoading(false);
        }
        let promises = [];

        if (filter.countries.length === 0) {
            promises.push(
                (async () => {
                    const results = await gApiClient.callApi(
                        'admin/getFilterCountries',
                        'GET',
                        {}
                    );
                    sortItems(results.data, SORT_TYPE_ALPHABETICAL);
                    dispatch(setAdminCountries(results.data));
                    setCountries(results.data);
                })()
            );
        }

        if (filter.filterMachines.length === 0) {
            promises.push(
                (async () => {
                    const results = await gApiClient.callApi(
                        'admin/getFilterMachines',
                        'GET',
                        {}
                    );
                    sortItems(results.data, SORT_TYPE_ALPHABETICAL);
                    // Add client ID
                    results.data.forEach((c) => {
                        if (c.internal_id) {
                            c.name = `${c.name} (${c.internal_id})`;
                        }
                    });

                    dispatch(setAdminFilterMachines(results.data));
                    setMachines(results.data);
                })()
            );
        }

        if (filter.fwVersions.length === 0) {
            promises.push(
                (async () => {
                    const results = await gApiClient.callApi(
                        'admin/getAllFWVersions',
                        'GET',
                        {}
                    );
                    results.data.sort((a, b) => a.localeCompare(b));
                    dispatch(setAdminFWVersions(results.data));
                    setFWVersions(results.data);
                })()
            );
        }

        if (promises.length > 0) {
            loadAllData(promises);
        }

        // Refresh the machines' status every X seconds
        const timer = setInterval(async () => {
            if (window.location.pathname === '/admin') {
                // Don't refresh while in admin view
                return;
            }

            const results = await gApiClient.callApi(
                'admin/getFilterMachines?return_all=1',
                'GET',
                {}
            );
            sortItems(results.data, SORT_TYPE_ALPHABETICAL);
            // Add client ID
            results.data.forEach((c) => {
                if (c.internal_id) {
                    c.name = `${c.name} (${c.internal_id})`;
                }
            });

            dispatch(setAdminFilterMachines(results.data));
            setMachines(results.data);
        }, PAGE_REFRESH_RATE);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const shouldMarkMachine = (item, value) => {
        const isMachine = item.previous_clusters;
        if (!isMachine) return value;

        const isCurrentFWVersion = item.fw_version === selectedFWVersion;
        const nonSupportedFWVersion = NON_SUPPORTED_FW_VERSIONS.includes(
            item.fw_version
        );

        if (isCurrentFWVersion || nonSupportedFWVersion) {
            // Don't allow selecting a machine with non-supported FW versions
            // or that has the same FW version
            return false;
        } else {
            return value;
        }
    };

    const renderListItem = (props) => {
        const item = props.item;
        const isMachine = item.previous_clusters;
        const hasParent = item.parent;
        const level = props.level || 0;

        let levelWidth = 0;
        if (level === 1) {
            levelWidth = 10;
        } else if (level === 2) {
            levelWidth = 20;
        } else if (level === 3) {
            levelWidth = 30;
        }

        const isCurrentFWVersion =
            isMachine && item.fw_version === selectedFWVersion;
        const nonSupportedFWVersion =
            isMachine && NON_SUPPORTED_FW_VERSIONS.includes(item.fw_version);

        return (
            <div className={classes.machineItemContainer}>
                <div
                    style={{
                        width: '5px',
                        height: '100%',
                        marginLeft: `${levelWidth}px`,
                        borderRight: '1px solid #B5BFCC',
                        backgroundColor: `${level === 2 ? '#B5BFCC' : null}`,
                    }}
                ></div>
                <div className={classes.machineItemLabel}>
                    <div>{isMachine ? item.alias : item.name}</div>
                    {hasParent && (
                        <div className={classes.machineItemSubLabel}>
                            {getMachineHierarchy(item.parent)}
                        </div>
                    )}
                </div>
                {isMachine && (
                    <div
                        className={clsx(
                            classes.machineFWVersion,
                            nonSupportedFWVersion
                                ? classes.nonSupportedFWVersion
                                : null,
                            isCurrentFWVersion ? classes.currentFWVersion : null
                        )}
                    >
                        <div>{item.fw_version}</div>
                    </div>
                )}
                <div className={classes.machineItemCheckboxContainer}>
                    <Checkbox
                        checked={item.selected ? true : false}
                        className={classes.itemCheck}
                        iconClassName={classes.itemCheckIcon}
                        disabled={isCurrentFWVersion || nonSupportedFWVersion}
                        checkedIconClassName={clsx(
                            classes.itemCheckedOnIcon,
                            classes.itemCheckIcon
                        )}
                        onChange={(v) => {
                            const machinesCopy = clone(machines);

                            const itemCopy = findItem(machinesCopy, item._id);
                            markItem(itemCopy, v, shouldMarkMachine);
                            setMachines(machinesCopy);
                            setSelectedMachines(
                                findSelectedMachines(machinesCopy)
                            );
                            const clust = findSelectedClusters(machinesCopy);
                            setSelectedClusters(clust);
                        }}
                    />
                </div>
            </div>
        );
    };

    const onSubmitForm = async () => {
        props.onLoading(true);
        setShowConfirmation(false);
        setError(null);

        try {
            await gApiClient.callApi(
                'admin/updateFW',
                'POST',
                {},
                {
                    machine_ids: selectedMachines.map((m) => m._id).join(','),
                    fw_version: selectedFWVersion,
                }
            );

            unmarkAll();
            setSuccessMessage(`FW Update Sent to Machines`);
        } catch (e) {
            console.error(e);
            setError(e.message);
            props.onLoading(false);
            return;
        }

        props.onLoading(false);
    };

    const unmarkAll = () => {
        const machinesCopy = clone(machines);

        machinesCopy.forEach((m) => {
            markItem(m, false);
        });
        setMachines(machinesCopy);
        setSelectedMachines(findSelectedMachines(machinesCopy));
        const clust = findSelectedClusters(machinesCopy);
        setSelectedClusters(clust);
    };

    const selectAll = () => {
        const machinesCopy = clone(machines);

        machinesCopy
            .filter((m) => m.visible)
            .forEach((m) => {
                markItem(m, true, shouldMarkMachine);
            });
        setMachines(machinesCopy);
        setSelectedMachines(findSelectedMachines(machinesCopy));
        const clust = findSelectedClusters(machinesCopy);
        setSelectedClusters(clust);
    };

    return (
        <div ref={formsWindow}>
            <Dialog
                onClose={() => setShowConfirmation(false)}
                open={showConfirmation}
                style={{ position: 'absolute' }}
                BackdropProps={{ style: { position: 'absolute' } }}
                container={() => {
                    return formsWindow.current;
                }}
                classes={{ paper: classes.dialog }}
            >
                <div className={classes.dialogMessage}>
                    Are you sure you want to upgrade FW for these machines?
                </div>
                <Button
                    className={classes.confirmSubmit}
                    label="Yes"
                    onClick={onSubmitForm}
                />
                <Button
                    className={classes.confirmSubmit}
                    label="No"
                    onClick={() => setShowConfirmation(false)}
                />
            </Dialog>

            <div className={clsx(classes.formRow, classes.formRowColumn)}>
                <Select
                    className={classes.formFieldFull}
                    hint={'Select Countries'}
                    multiple
                    autoComplete
                    items={countries.map((c) => ({
                        value: c._id,
                        label: c.name,
                    }))}
                    renderValue={(selected) => selected.join(', ')}
                    value={selectedCountries}
                    onChange={(newCountries) => {
                        setSelectedCountries(newCountries);

                        const newRegions = new Set();
                        newCountries.forEach((c) => {
                            const itemCopy = findItem(countries, c.value);
                            itemCopy.children.forEach((region) => {
                                newRegions.add(region._id);
                            });
                        });

                        // If no regions are selected -> make sure no machines are shown
                        setSelectedRegions(
                            newRegions.size > 0 ? newRegions : new Set(['*'])
                        );
                    }}
                />
            </div>

            <div className={classes.formRow}>
                <Select
                    className={classes.formFieldFull}
                    items={fwVersions.map((x) => ({ value: x, label: x }))}
                    value={
                        selectedFWVersion
                            ? {
                                  value: selectedFWVersion,
                                  label: setSelectedFWVersion,
                              }
                            : null
                    }
                    onChange={(x) => {
                        setSelectedFWVersion(x.value);
                    }}
                    hint={'Select FW Version'}
                    caseSensitive={true}
                />
            </div>

            {selectedFWVersion && selectedRegions.size > 0 && (
                <div className={clsx(classes.formRow, classes.formRowColumn)}>
                    <div className={classes.formRowTitle}>
                        Select Clients, Sites or Machines
                    </div>
                    {machines && machines.length > 0 && (
                        <SearchField
                            className={classes.search}
                            placeholder="Search"
                            onSearch={setClientsSearchText}
                            inputClass={classes.searchFieldInput}
                            iconClass={classes.searchFieldIcon}
                        />
                    )}
                    <div className={classes.buttonRow}>
                        <Button
                            className={classes.sortButton}
                            labelClass={clsx(
                                classes.buttonLabel,
                                classes.clearLabel
                            )}
                            label={
                                <>
                                    <div className={classes.clearLabelText}>
                                        Clear Selection
                                    </div>
                                </>
                            }
                            onClick={unmarkAll}
                        />
                        <Button
                            className={classes.sortButton}
                            labelClass={clsx(
                                classes.buttonLabel,
                                classes.clearLabel
                            )}
                            label={
                                <>
                                    <div className={classes.clearLabelText}>
                                        Select All
                                    </div>
                                </>
                            }
                            onClick={selectAll}
                        />
                    </div>
                    <MachineList
                        className={classes.list}
                        items={machines}
                        regions={selectedRegions}
                        filter={clientsSearchText}
                        customListItem={renderListItem}
                        listItemClass={classes.listItemClass}
                        collapseAllTheWay={true}
                    />
                </div>
            )}
            {error && (
                <div
                    className={classes.formRow}
                    style={{
                        justifyContent: 'center',
                    }}
                >
                    <div className={classes.error}>{error}</div>
                </div>
            )}
            <div
                className={classes.formRow}
                style={{ justifyContent: 'center' }}
            >
                <Button
                    className={classes.confirmSubmit}
                    label="Update Machines"
                    disabled={disallowFormSubmission}
                    rightIcon={forwardIcon}
                    onClick={() => setShowConfirmation(true)}
                />
            </div>
            {successMessage && (
                <div className={classes.successMessage}>{successMessage}</div>
            )}
        </div>
    );
}
