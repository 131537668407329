import React from 'react';
import { useEffect } from 'react';
import {
    Route,
    BrowserRouter,
    Switch,
    useLocation,
    useHistory,
    Redirect,
} from 'react-router-dom';
import Authentication from './modules/Authentication';
import { useDispatch } from 'react-redux';
import { setLoginStatus } from './store/actions/user';
import SearchMachine from './pages/Machines/SearchMachine';
import Login from './pages/Login';
import SingleMachineDashboard from './pages/Machines/SingleMachineDashboard/SingleMachineDashboard';
import SingleMachineAction from './pages/Machines/SingleMachineAction/SingleMachineAction';
import StatsSection from './pages/Stats/StatsSection';
import ReportsSection from './pages/Reports/ReportsSection';
import OverviewSection from './pages/Overview/OverviewSection';
import AdminSection from './pages/Admin/AdminSection';
import Register from './pages/Register';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import ConfirmEmail from './pages/ConfirmEmail';
import UnconfirmedEmail from './pages/UnconfirmedEmail';
import Unauthorized from './pages/Unauthorized';
import Settings from './pages/Settings';
import FWUpdateStatus from './pages/FOTA/FWUpdateStatus';
import RecipeTypes from './pages/RecipeTypes/RecipeTypes';
import { APP_BAR_VISIBLE, setAppBarVisibility } from './store/actions/ui';

/** Checks if user is logged-in - if not, redirects to login page */
function checkForLoginAndRedirect(history, location, dispatch) {
    const isLoginRequiredPage = ![
        '/register',
        '/login',
        '/forgot-password',
    ].includes(location.pathname);

    const isUserPage = [
        '/register',
        '/login',
        '/forgot-password',
        '/reset-password',
        '/confirm-email',
        '/settings',
    ].includes(location.pathname);

    const isLoginPage = [
        '/register',
        '/login',
        '/forgot-password',
        '/reset-password',
        '/confirm-email',
    ].includes(location.pathname);

    Authentication.refreshUserDetails(() => {
        Authentication.getLoginStatus((status) => {
            // Update login status in the store
            dispatch(setLoginStatus(status));

            if (
                status === Authentication.LOGIN_STATUS_NOT_LOGGED_IN &&
                location.pathname === '/reset-password'
            ) {
                // Do nothing - let user reset password
            } else if (
                status === Authentication.LOGIN_STATUS_NOT_LOGGED_IN &&
                isLoginRequiredPage
            ) {
                // Not logged-in - redirect to registration page
                history.replace('/register');
            } else if (
                status === Authentication.LOGIN_STATUS_UNCONFIRMED &&
                !isUserPage
            ) {
                // Unconfirmed email address
                history.replace('/unconfirmed-email');
            } else if (
                status === Authentication.LOGIN_STATUS_NO_PERMISSIONS &&
                !isUserPage
            ) {
                // User not authorized for NOC permissions
                history.replace('/unauthorized');
            } else if (status === Authentication.LOGIN_STATUS_LOGGED_IN) {
                // User logged-in

                if (location.pathname !== '/settings') {
                    dispatch(setAppBarVisibility(APP_BAR_VISIBLE));
                }

                if (isLoginPage) {
                    history.replace('/');
                }
            }
        });
    });
}

/** An interception switch - intercept each time URL is changed, and checks if user is logged-in -
 * if not, redirect to login screen. */
function InterceptSwitch(props) {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        console.log('Location changed', location.pathname);
        checkForLoginAndRedirect(history, location, dispatch);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, history, dispatch]);

    return <Switch>{props.children}</Switch>;
}

export function MainRoutes() {
    return (
        <Switch>
            {Authentication.getWebsiteStage() === Authentication.STAGE_PROD ? (
                <Redirect exact from="/" to="/overview" />
            ) : (
                <Redirect exact from="/" to="/machines" />
            )}
            <Route exact path="/machines" component={SearchMachine} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/reset-password" component={ResetPassword} />
            <Route exact path="/confirm-email" component={ConfirmEmail} />
            <Route exact path="/settings" component={Settings} />
            <Route
                exact
                path="/unconfirmed-email"
                component={UnconfirmedEmail}
            />
            <Route exact path="/unauthorized" component={Unauthorized} />
            <Route
                exact
                path="/machines/:machineId/dashboard"
                component={SingleMachineDashboard}
            />
            <Route
                exact
                path="/machines/:machineId/action"
                component={SingleMachineAction}
            />
            <Route exact path="/stats" component={StatsSection} />
            <Route exact path="/reports" component={ReportsSection} />
            <Route exact path="/stats/results" component={StatsSection} />
            <Route exact path="/overview" component={OverviewSection} />
            <Route exact path="/overview/filter" component={OverviewSection} />
            <Route exact path="/overview/kpis" component={OverviewSection} />
            <Route exact path="/admin" component={AdminSection} />
            <Route exact path="/fw-update-status" component={FWUpdateStatus} />
            <Route exact path="/recipe-types" component={RecipeTypes} />
        </Switch>
    );
}
export function Router(props) {
    return (
        <BrowserRouter>
            <InterceptSwitch>{props.children}</InterceptSwitch>
        </BrowserRouter>
    );
}
